<template>
  <div>
    <b-form
      align="left"
      id="formularioResiduos"
      @submit="modalEnviar"
      @reset="cancelarModal"
    >
      <b-card header="Jornada">
        <b-form-row>
          <b-col cols="12" md="6">
            <label for="fecha" class="d-block">Fecha de realización:</label>
            <b-form-datepicker
              id="fecha"
              v-model="form.fecha"
              class="mb-2 mr-2"
              placeholder="YY/MM/DD"
              locale="es"
              labelNoDateSelected="No hay fecha"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              labelHelp="Use las flechas del teclado para navegar entre fechas"
              required
              :disabled="verDetalles ? true : false"
            >
            </b-form-datepicker>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              id="group-descripcion"
              label="Nombre / Descripción"
              label-for="descripcion"
            >
              <b-form-input
                id="descripcion"
                v-model="form.descripcion"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-num_acta"
              label="Número de acta"
              label-for="num_acta"
            >
              <b-form-input
                id="num_acta"
                v-model="form.num_acta"
                :disabled="verDetalles ? true : false"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12" md="4">
            <b-form-group
              id="group-registro_fotografico"
              label="Registro Fotográfico"
              label-for="registro_fotografico"
            >
              <b-form-file
                id="registro_fotografico"
                :state="Boolean(form.registro_fotografico)"
                placeholder="Elige un archivo"
                browseText="Buscar"
                accept=".jpg, .JPEG, .JPG"
                @change="imagenRegistro"
                v-if="!cargadoRegistro"
                :disabled="verDetalles ? true : false"
              ></b-form-file>

              <b-button
                block
                variant="primary"
                class="mr-2"
                v-if="cargadoRegistro"
                @click="descargarPDF(form.registro_fotografico, 'RegistroFotografico')"
              >
                Descargar
              </b-button>
            </b-form-group>
          </b-col> -->

          <b-col cols="12" md="4" align-self="end">
            <b-form-group
              id="group-registro_acta"
              label="Registro acta"
              label-for="registro_ acta"
              v-if="!editar || resubirActa"
            >
              <b-form-file
                id="registro_acta"
                :state="Boolean(form.acta)"
                placeholder="Elige un archivo"
                browseText="Buscar"
                accept=".pdf, .PDF"
                @change="imagenActa"
                v-if="!cargadoActa"
                :disabled="verDetalles ? true : false"
              ></b-form-file>
            </b-form-group>

            <b-button
              block
              variant="primary"
              class="mr-2 mb-4"
              size="sm"
              v-if="editar && !resubirActa"
              @click="recargarActa"
            >
              Subir nueva acta
            </b-button>
          </b-col>

          <b-col cols="12" md="4" align-self="end">
            <b-button
              block
              variant="primary"
              class="mr-2 mb-4"
              :disabled="!form.acta"
              @click="descargarPDF(form.acta, 'acta')"
              size="sm"
            >
              Descargar Acta
            </b-button>
          </b-col>
        </b-form-row>
      </b-card>

      <br />

      <b-card
        bg-variant="light"
        header="Asistentes"
        header-bg-variant="secondary"
        header-text-variant="white"
        align="left"
        v-if="editar"
      >
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group
              id="group-identificacion"
              label="identificacion"
              label-for="identificacion"
            >
              <b-form-input
                id="identificacion"
                v-model="formAgregar.identificacion"
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12" md="3">
            <b-form-group id="group-nombre" label="Nombre" label-for="nombre">
              <b-form-input
                id="nombre"
                v-model="formAgregar.nombre"
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col> -->

          <b-col cols="12" md="4" align-self="end">
            <b-button
              block
              variant="primary"
              class="mr-2 mb-3"
              @click="crearAsistente"
            >
              Agregar
            </b-button>
          </b-col>

          <b-col cols="12" md="4" align-self="end">
            <div class="subirAsistentes">
              <b-form-file
                v-model="archivo"
                ref="subirArchivo"
                class="subirExcel"
                accept="application/vnd.ms-excel, vnd.sealed.xls, text/csv"
                @change="onLoad"
                plain
              ></b-form-file>
              <b-button
                block
                variant="primary"
                class="mb-3"
                @click="abrirSubirArchivo"
                >Subir</b-button
              >
            </div>
          </b-col>

          <b-col cols="12" v-if="dataAsistentesTable.length > 0">
            <b-table
              id="atencionesVeterinarias"
              striped
              responsive
              small
              bordered
              :items="dataAsistentesTable"
              :fields="camposTabla"
              :per-page="perPage"
              :current-page="currentPage"
              :tbody-tr-class="rowClass"
              select-mode="single"
              ref="selectableTable"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(seleccionar)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-if="dataAsistentesTable.length > 0">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="consultaLicencias"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-card>

      <br /><br />

      <div class="text-right">
        <b-button class="mr-3" type="submit" v-if="!editar"> Guardar </b-button>
        <b-button class="mr-3" type="submit" v-if="editar"> Guardar </b-button>

        <b-button type="reset"> Cancelar </b-button>
      </div>
    </b-form>

    <b-modal
        id="ModalNuevoRegistro"
        v-model="modalNuevoRegistro"
        size="lg"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
    >
      <b-col cols="12">
        <p>El asistente no se encuentra registrado, así que se creara por defecto y se requerira su número de contacto</p>
        <form ref="formTelefono" @submit.stop.prevent="handleSubmit">

          <b-form-row>
            <b-col cols="12" md="3">
              
              <b-form-group
                label="Nombre 1"
                label-for="nombre1"
                :state="checknombre1"
              >
                <b-form-input
                  id="nombre1"
                  v-model="formAgregar.nombre"
                  :state="checknombre1"
                  required
                ></b-form-input>
              </b-form-group>

            </b-col>

            <b-col cols="12" md="3">
              
              <b-form-group
                label="Nombre 2"
                label-for="nombre2"
                :state="checknombre2"
              >
                <b-form-input
                  id="nombre2"
                  v-model="formAgregar.nombre_2"
                  :state="checknombre2"
                  required
                ></b-form-input>
              </b-form-group>

            </b-col>

            <b-col cols="12" md="3">
              
              <b-form-group
                label="Apellido 1"
                label-for="apellido1"
                :state="checkapellido1"
              >
                <b-form-input
                  id="apellido1"
                  v-model="formAgregar.apellido_1"
                  :state="checkapellido1"
                  required
                ></b-form-input>
              </b-form-group>

            </b-col>

            <b-col cols="12" md="3">
              
              <b-form-group
                label="Apellido 2"
                label-for="apellido2"
                :state="checkapellido2"
              >
                <b-form-input
                  id="apellido2"
                  v-model="formAgregar.apellido_2"
                  :state="checkapellido2"
                  required
                ></b-form-input>
              </b-form-group>

            </b-col>


          </b-form-row>


          <b-form-group
            label="Número de Contacto"
            label-for="telefono"
            :state="checktelefono"
          >
            <b-form-input
              id="telefono"
              v-model="formAgregar.telefono"
              :state="checktelefono"
              required
            ></b-form-input>
          </b-form-group>

        </form>
      </b-col >
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { encontrarError } from "../../contantesErrores";
import XLSX from "xlsx";
// import axios from "axios";
// import { RCDGETLICENCIABYNUMACTO } from "../../constantes";
import moment from 'moment'
export default {
  name: "agregarEvento",
  data() {
    return {
      modalNuevoRegistro: false,
      dataAsistentesTable: [],
      archivo: [],
      editar: false,
      cargadoRegistro: false,
      cargadoActa: false,
      verDetalles: false,
      resubirActa: false,
      form: {
        fecha: null,
        descripcion: null,
        registro_fotografico: null,
        acta: null,
        num_acta: null,
      },
      formAgregar: {
        identificacion: null,
        nombre: null,
        nombre_2: null,
        apellido_1: null,
        apellido_2: null,
        telefono : null,
        telefonoState: null,
      },
      camposTabla: [
        {
          key: "identificacion",
          label: 'Identificación'
        },
        "nombre_1",
        "nombre_2",
        "apellido_1",
        "apellido_2",
        {
          key: "tel_celular",
          label: 'Teléfono'
        }
      ],
      perPage: 10,
      currentPage: 1,
    };
  },
  async mounted() {
    if (this.eventoSeleccionado.id) {
      this.editar = true;
      try {
        await this.cargarEventoById(this.eventoSeleccionado.id);
        console.log("evento cargado", this.eventoCargado);
        this.mapearEventoCargado();
        await this.TraerAsistentesEvento(this.eventoSeleccionado.id);
        if (this.asistentesServicios.length > 0) {
          console.log("asistentes", this.asistentesServicios);
          this.dataAsistentesTable = this.asistentesServicios;
        }
      } catch (e) {
        encontrarError(this, e.data);
      }
    }
  },
  computed: {
    ...mapState("recicladores", [
      "asistentesServicios",
      "eventoSeleccionado",
      "eventoCargado",
      "resultCreateEvento",
      "recicladorCargadoByIdentificacion",
      "resultCreateAsistentesEventos",
      "resultCreateReciclador"
    ]),
    rows() {
      return this.dataAsistentesTable.length;
    },
    checknombre1(){
      return this.formAgregar.nombre == null || this.formAgregar.nombre == ''? false:true
    },
    checknombre2(){
      return this.formAgregar.nombre_2 == null || this.formAgregar.nombre_2 == ''? false:true
    },
    checkapellido1(){
      return this.formAgregar.apellido_1 == null || this.formAgregar.apellido_1 == ''? false:true
    },
    checkapellido2(){
      return this.formAgregar.apellido_2 == null || this.formAgregar.apellido_2 == ''? false:true
    },
    checktelefono(){
      return this.formAgregar.telefono == null || this.formAgregar.telefono == ''? false:true
    },

  },
  methods: {
    ...mapActions(["activarLoading"]),
    ...mapActions("recicladores", [
      "TraerAsistentesEvento",
      "cargarEventoById",
      "createEvento",
      "editEvento",
      "cargarRecicladorByIdentificacion",
      "createAsistenteEvento",
      "createReciclador"
    ]),

    rowClass(item, type) {
      console.log(item)
      if (item.noexiste) return "noExisteAsistente"
      if (!item || type !== "row") return;
      return "rowspace";
    },

    mapearEventoCargado() {
      this.form = { ...this.eventoCargado };
    },

    recargarActa(){
      console.log('resubir')
      this.resubirActa = true
    },

    abrirSubirArchivo() {
      let btn = this.$refs.subirArchivo.$el;
      btn.click();
    },

    async onRowSelected(items) {
      console.log("onRow", items);
    },

    imagenRegistro(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo, "RF");
    },

    imagenActa(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo, "Ac");
    },

    crearBase64(file, lugar) {
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let archivo = e.target.result;
          let base = archivo.split("base64,");
          let pdf = base[1];

          if (lugar == "RF") {
            this.form.registro_fotografico = pdf;
          }
          if (lugar == "Ac") {
            this.form.acta = pdf;
          }
        };
        reader.readAsDataURL(file);
      } else {
        if (lugar == "RF") {
          this.form.registro_fotografico = null;
        }
        if (lugar == "Ac") {
          this.form.acta = null;
        }
      }
    },

    descargarPDF(file, nombre) {
      const linkSource = `data:image/jpg;base64,${file}`;
      const downloadLink = document.createElement("a");
      const fileName = `pdf_${nombre}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    modalEnviar(ev) {
      ev.preventDefault();
      if (this.form.fecha != null) {
        this.$bvModal
          .msgBoxConfirm("Se van a guardar los datos, ¿Desea continuar?.", {
            title: "Por favor confirme",
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: "Continuar",
            cancelTitle: "Cancelar",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.enviarFormulario();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.modalMensaje("Por favor seleccione una fecha de nacimiento");
      }
    },

    modalCrearNuevoRegistro(){
      this.modalNuevoRegistro = true
    },

    checkTelefonoRequerido() {
      const valid = this.$refs.formTelefono.checkValidity()
      this.formAgregar.telefonoState = valid
      return valid
    },

    resetModal() {
      this.formAgregar.nombre_1 = null
      this.formAgregar.nombre_2 = null
      this.formAgregar.apellido_1 = null
      this.formAgregar.apellido_2 = null
      this.formAgregar.telefono = null
      this.formAgregar.telefonoState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkTelefonoRequerido()) {
        return
      }
      this.crearRegistro()
      this.$nextTick(() => {
        this.$bvModal.hide('ModalNuevoRegistro')
      })
    },

    async enviarFormulario() {
      this.activarLoading(true);
      let datosCrear = {
        evento: { ...this.form },
      };

      try {
        if (this.editar) {
          await this.editEvento(datosCrear);
        } else {
          await this.createEvento(datosCrear);
        }
        if (this.resultCreateEvento.data.status == 200) {
          this.activarLoading(false);
          this.modalOk();
        }
      } catch (e) {
        this.activarLoading(false);
        encontrarError(this, e.data);
      }
    },

    modalOk() {
      this.$bvModal
        .msgBoxOk("Los Datos fueron guardados correctamente.", {
          title: "Confirmación",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(async () => {
          console.log("creado");
          this.cancelarModal(true);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async crearRegistro(){
      let registroCrear={
        registro: {
          id: null,
          fecha_cargue: moment(),
          id_asociacion: null,
          foto: null,
          nombre_1: this.formAgregar.nombre,
          nombre_2: this.formAgregar.nombre_2,
          apellido_1: this.formAgregar.apellido_1,
          apellido_2: this.formAgregar.apellido_2,
          sexo: null,
          pobla_rom: null,
          tipo_iden: 1,
          identificacion: this.formAgregar.identificacion,
          id_ciudad_expedicion: 0,
          cabeza_hogar: 0,
          id_ciudad_nacim: 0,
          fecha_nacim: moment(),
          edad: null,
          direccion: '-',
          id_ciudad_res: 0,
          estrato: 0,
          tel_fijo: this.formAgregar.telefono,
          tel_celular: this.formAgregar.telefono,
          lee: 0,
          escribe: 0,
          nivel_educativo: 0,
          licencia_cond: 0,
          categoria: null,
          tipo_vehiculo: null,
          seguridad_social: null,
          pension: null,
          arl: null,
          tipo_vivienda: null,
          vivienda_es: null,
          meses_actividad: 0,
          actividad_anterior: null,
          dias_semana: 0,
          horas_semana: 0,
          actividad_alterna: null,
          lugar_rec_material: null,
          vehiculo_utilizado: null,
          titularidad_vehiculo: null,
          rec_papel: 0,
          rec_carton: 0,
          rec_plastico: 0,
          rec_aluminio: 0,
          rec_vidrio: 0,
          rec_otro: 0,
          otro_cual: null,
          sitio_venta: null,
          ubicacion: null,
          ingresos_semana: 0,
        }
      }
      try{
          await this.createReciclador(registroCrear);
          if (this.resultCreateReciclador.data.status == 200) {
            await this.guardarAsistenteEvento(this.resultCreateReciclador.data.data)
            this.adicionarAsistenteTabla(false)
          }
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    crearAsistente(){
      this.verificarAsistente(this.formAgregar)
    },

    async verificarAsistente(asistente) {
      if (asistente.identificacion && asistente.identificacion != '') {
        this.activarLoading(true);
        try {
          await this.cargarRecicladorByIdentificacion(asistente.identificacion);
          if (this.recicladorCargadoByIdentificacion) {

              let yaRegistrado = false;
              this.dataAsistentesTable.forEach(item=>{
                if(item.identificacion == asistente.identificacion){
                  yaRegistrado = true
                }
              })

              if(!yaRegistrado){
                this.guardarAsistenteEvento(this.recicladorCargadoByIdentificacion);
                this.adicionarAsistenteTabla(true)
              }else{
                this.modalMensaje('La persona ya esta registrado en esta jornada')
              }

            this.activarLoading(false);
          }else{
            this.modalCrearNuevoRegistro()
          }
          this.activarLoading(false);
        } catch (e) {
          this.activarLoading(false);
          encontrarError(this, e.data);
        }
      } else {
        this.modalMensaje(
          "El campo Identificación debe estar diligenciado"
        );
      }
    },

    async guardarAsistenteEvento(asistente){
      let asistenteEvento={
        asistenteevento: {
          id_registro: asistente.id,
          id_evento: this.eventoSeleccionado.id
        }
      }
      try{
        await this.createAsistenteEvento(asistenteEvento)
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    adicionarAsistenteTabla(existe){
      let asist;
      if(existe){
        asist = {
          identificacion: this.recicladorCargadoByIdentificacion.identificacion,
          nombre_1: this.recicladorCargadoByIdentificacion.nombre_1,
          nombre_2: this.recicladorCargadoByIdentificacion.nombre_2,
          apellido_1: this.recicladorCargadoByIdentificacion.apellido_1,
          apellido_2: this.recicladorCargadoByIdentificacion.apellido_1,
          tel_celular: this.recicladorCargadoByIdentificacion.tel_celular,
        }
      }else {
        asist = {
          identificacion: this.formAgregar.identificacion,
          nombre_1: this.formAgregar.nombre,
          nombre_2: this.formAgregar.nombre_2,
          apellido_1: this.formAgregar.apellido_1,
          apellido_2: this.formAgregar.apellido_2,
          tel_celular: this.formAgregar.telefono
        }
      }
      this.dataAsistentesTable.push(asist)
      this.formAgregar.identificacion=null;
      this.formAgregar.nombre=null;
      this.formAgregar.nombre_2=null;
      this.formAgregar.apellido_1=null;
      this.formAgregar.apellido_2=null;
      this.formAgregar.tel_celular=null;
    },

    cancelarModal() {
      if (this.editar) {
        this.$emit("resetearSeleccion");
      } else {
        this.$emit("resetear");
      }
      this.editar = false;
      this.resubirActa = false
    },

    modalMensaje(mensaje) {
      this.$bvModal
        .msgBoxOk(mensaje, {
          title: "Error",
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          console.log("creado");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CARGAR ARCHIVO

    explorarArchivo(f) {
      this.dataCompleta = [];
      this.tableRow = [];
      this.tableData = [];
      var reader = new FileReader(),
        vm = this;
      reader.onload = (e) => {
        var data = e.target.result,
          workbook = XLSX.read(data, { type: "binary" }),
          sheetName = workbook.SheetNames[0],
          sheet = workbook.Sheets[sheetName];
        for (var row = 1; ; row++) {
          if (sheet["A" + row] == null) {
            break;
          }

          for (var col = 65; col <= 90; col++) {
            var c = String.fromCharCode(col); // get 'A', 'B', 'C' ...
            var key = "" + c + row;
            if (sheet[key] == null) {
              break;
            }
            vm.tableRow.push(sheet[key]["w"]);
          }
          vm.tableData.push(vm.tableRow);
          vm.tableRow = [];
        }
        console.log("rows", vm.tableData);
        this.crearDataCompleta(vm.tableData);
      };
      reader.readAsBinaryString(f);
    },

    onLoad() {
      this.interval = setInterval(this.verificarArchivo, 500);
    },

    verificarArchivo() {
      if (this.archivo != null) {
        this.explorarArchivo(this.archivo);
        clearInterval(this.interval);
      }
    },

    async crearDataCompleta(array) {
      for (let i = 1; i < array.length; i++) {
        let items = array[i];
        let linea = {};
        for (let n = 0; n < array[0].length; n++) {
          let encabezado = array[0][n];
          linea[encabezado] = items[n];
        }

        this.formAgregar.identificacion = linea.identificacion;
        this.formAgregar.nombre = linea.nombre_1;
        this.formAgregar.nombre_2 = linea.nombre_2;
        this.formAgregar.apellido_1 = linea.apellido_1;
        this.formAgregar.apellido_2 = linea.apellido_2;
        this.formAgregar.telefono = linea.tel_celular;

        await this.verificarAsistenteSubido(this.formAgregar);
      }
    },

    async verificarAsistenteSubido(asistente) {
      console.log('persona subida', asistente)
      this.activarLoading(true);
      try {
        await this.cargarRecicladorByIdentificacion(asistente.identificacion);
        if (this.recicladorCargadoByIdentificacion) {
          if(this.recicladorCargadoByIdentificacion.nombre_1.toUpperCase().trim() == asistente.nombre.toUpperCase().trim() 
            || this.recicladorCargadoByIdentificacion.nombre_2.toUpperCase().trim() == asistente.nombre.toUpperCase().trim()){

              let yaRegistrado = false;
              this.dataAsistentesTable.forEach(item=>{
                if(item.identificacion == asistente.identificacion){
                  yaRegistrado = true
                }
              })
              if(!yaRegistrado){
                this.guardarAsistenteEvento(this.recicladorCargadoByIdentificacion);
                this.adicionarAsistenteTabla(true)
              }else{
                this.modalMensaje('La persona ya esta registrado en esta jornada')
              }
            }else{
              this.modalMensaje(`El nombre ingresado no coincide con el nombre registrado para este número de identificación ${asistente.identificacion}`)
            }
          this.activarLoading(false);
        }else{
          this.crearRegistro()
        }
        this.activarLoading(false);
      } catch (e) {
        this.activarLoading(false);
        encontrarError(this, e.data);
      }
    },
  },
};
</script>

<style scoped>
</style>