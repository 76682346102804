<template>
  <div>
    <div class="cajaSuperior">
      <b-row>
        <b-col cols="12" md="3">
          <h2>
            criterios de <br />
            consulta
          </h2>
        </b-col>

        <!-- Formulario consulta -->
        <b-col>
          <div class="contenedorForm pl-5">
            <FormularioConsultaEventos />
          </div>
        </b-col>
      </b-row>
    </div>

    <br />
    <br />
    <b-row v-if="eventosServicios.length > 0">
      <b-col>
        <b-table
          id="consultaLicencias"
          striped
          responsive
          small
          bordered
          :items="eventosServicios"
          :fields="camposTabla"
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-tr-class="rowClass"
          select-mode="single"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(seleccionar)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
    
    <b-row v-if="eventosServicios.length > 0">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="consultaJornadas"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-modal
        id="ModalAgregarEventoB"
        v-model="mostrarModalAgregar"
        hide-footer
        size="lg"
    >
      <template #modal-header>
        <h5>Registro de Jornadas</h5>
        <b-button @click="cancelar(false)" variant="link">
          x
        </b-button>
      </template>
      <div class="d-block text-center p-3">
        <AgregorEvento @resetearSeleccion="cancelar" />
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import FormularioConsultaEventos from "../../components/recicladores/FormularioConsultaEventos.vue";
import AgregorEvento from '../../components/recicladores/AgregarEvento.vue'
export default {
  name: "ConsultaCensoRecicladores",
  components: {
    FormularioConsultaEventos,
    AgregorEvento
  },
  data() {
    return {
      mostrarModalAgregar: false,
      camposTabla: [
        "seleccionar",
        {
          key: 'fecha',
          formatter: (value) => {
            return moment(value).format("DD-MM-YYYY");
          },
        },
        "num_acta",
        "descripcion",
      ],
      itemSeleccionado: [],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapState("recicladores", [
      "eventosServicios",
      "eventoSeleccionado",
    ]),
    rows() {
      return this.eventosServicios.length;
    },
    validarButton() {
      if (this.itemSeleccionado.length > 0) return false;
      return true;
    },
  },
  methods: {
    ...mapActions("recicladores", ["asignarEventoSeleccionado"]),
    rowClass(item, type) {
      if (!item || type !== "row") return;
      return "rowspace";
    },

    onRowSelected(items) {
      this.itemSeleccionado = items;
      if(items.length>0){
        this.asignarEventoSeleccionado(items[0]);
        this.abrirModalAgregar()
      }else{
        this.asignarEventoSeleccionado({});
      }
    },

    abrirModalAgregar(){
      this.mostrarModalAgregar = true
    },

    cancelar(guardar){
      console.log('aqui cancelar', guardar)
      this.cerrarModalAgregar()
      this.asignarEventoSeleccionado({});
    },

    cerrarModalAgregar(){
      this.cerrarModal=true
      this.$bvModal.hide('ModalAgregarEventoB')
    }
  },
};
</script>