<template>
  <div>  
    <b-form @submit.prevent="enviarForm">
      <b-form-row>
        <b-col cols="12" md="4">
          <label  for="fechaInicio">Fecha Inicio:</label>
          <div class="d-flex align-items-center">
            <b-form-datepicker 
                id="fechaInicio" 
                v-model="form.fecha_inicio" 
                class="mb-2 mr-2" 
                placeholder="YYYY/MM/DD" 
                locale="es"
                labelNoDateSelected="No hay fecha"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                labelHelp="Use las flechas del teclado para navegar entre fechas"
              ></b-form-datepicker>
              <b-icon icon="x-circle" variant="danger" @click="borrarFechaInicio" v-if="this.form.fecha_inicio!=null" class="mb-1"></b-icon>
          </div>
        </b-col>

        <b-col cols="12" md="4">
          <label  for="fechaFin">Fecha Fin:</label>
          <div class="d-flex align-items-center">
              <b-form-datepicker 
                  id="fechaFin" 
                  v-model="form.fecha_fin" 
                  class="mb-2 mr-2" 
                  placeholder="YYYY/MM/DD" 
                  locale="es"
                  labelNoDateSelected="No hay fecha"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  labelHelp="Use las flechas del teclado para navegar entre fechas"
              ></b-form-datepicker>
              <b-icon icon="x-circle" variant="danger" @click="borrarFechaFin" v-if="this.form.fecha_fin!=null" class="mb-1"></b-icon>
          </div>
        </b-col>

        <b-col cols="12" md="4" align-self="end" order-md="3" order="5">
          <b-button 
            class="vertical-align mb-2" 
            variant="secondary"
            block 
            @click="abrirModalAgregar"
          >
            <b-icon icon="plus-circle"></b-icon>
            Agregar
          </b-button>
        </b-col>

        <b-col cols="12" md="4" order-md="4" order="3">
          <label for="nombre_evento">Nombre jornada: </label>
          <b-form-input
            id="nombre_evento"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Nombre jornada"
            v-model="form.nombre_evento"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="4" order-md="5" order="4">
          <label for="num_acta">Acta No: </label>
          <b-form-input
            id="num_acta"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Acta No."
            v-model="form.num_acta"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="4" align-self="end" order="6">
          <b-button
            class="vertical-align mb-1"
            variant="secondary"
            type="submit"
            block
          >
            <b-icon icon="search"></b-icon>
            Consultar
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>

    <b-modal
        id="ModalAgregarEvento"
        v-model="mostrarModalAgregar"
        hide-footer
        size="lg"
    >
      <template #modal-header>
        <h5>Registro de Jornadas</h5>
        <b-button @click="cancelar(false)" variant="link">
          x
        </b-button>
      </template>
      <b-overlay :show="loading" rounded="sm" z-index=999>
        <div class="d-block text-center p-3">
          <AgregorEvento @resetear="cancelar" />
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import AgregorEvento from './AgregarEvento.vue'
import { mapActions, mapState } from "vuex";
import { encontrarError } from "../../contantesErrores";

export default {
  name: "FormularioConsultaRecicladores",
  components:{
    AgregorEvento
  },
  data() {
    return {
      animalSelec: {},
      cerrarModal: false,
      mostrarModalAgregar:false,
      form: {
        fecha_inicio: null,
        fecha_fin: null,
        nombre_evento: null,
        num_acta: null,
      },
    };
  },
  async mounted(){
     this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(!this.cerrarModal && modalId=="ModalAgregarEvento"){
        bvEvent.preventDefault();
      }else{
        this.cerrarModal=false
      }
    })
  },
  computed:{
    ...mapState(['loading'])
  },
  methods: {
    ...mapActions("recicladores", [ "buscarEventos"]),

    async enviarForm() {
      console.log("formulario", this.form);
      let filtros = {
        eventoparams: this.form
      }
      try {
        await this.buscarEventos(filtros);
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    borrarFechaInicio(){
      this.form.fecha_inicio=null
    },

    borrarFechaFin(){
      this.form.fecha_fin=null
    },

    abrirModalAgregar(){
      this.mostrarModalAgregar = true
    },

    cancelar(guardar){
      console.log('aqui cancelar', guardar)
      this.cerrarModalAgregar()
      if(guardar == true){
        this.enviarForm()
      }
    },

    cerrarModalAgregar(){
      console.log('tal vez aqui')
      this.cerrarModal=true
      this.$bvModal.hide('ModalAgregarEvento')
    }
  },
};
</script>

<style scoped>
</style>